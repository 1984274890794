<template>
    <layout>
        <h2 class="mb-4">Birthdays Calendar</h2>
        <div class="birthdays-calendar"
             :style="{ backgroundImage: 'url(' + require('@/assets/images/calendar/birthday.jpg') + ')' }">
            <div class="row">
                <b-card class="month-card col-xs-12 col-sm-6 col-md-6 col-lg-4"
                        v-for="(monthName, month) in birthdays.months"
                        :key="month"
                >
                    <h5 class="font-size-20 font-weight-semibold">{{ monthName }}</h5>
                    <div class="birthdays-days-block">
                        <div class="birthdays-days-row"
                             v-for="(employees, day) in birthdays.birthdays[month]"
                             :key="day"
                        >
                            <div class="mb-2"
                                 v-for="(employee, key) in employees"
                                 :key="key"
                            >
                                <div class="day rounded-pill font-size-16 font-weight-semibold text-center d-inline-block p-1">{{ day }}</div>
                                <div class="d-inline font-size-15 font-weight-semibold">
                                    {{ employee.name ? employee.name : '' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
        <img id="baloon_1" class="baloon-infinite" :src="require('@/assets/images/calendar/baloon_4.png')" alt="baloon" />
        <img id="baloon_2" class="baloon-infinite" :src="require('@/assets/images/calendar/baloon_2.png')" alt="baloon" />
        <img id="baloon_3" class="baloon-infinite" :src="require('@/assets/images/calendar/baloon_3.png')" alt="baloon" />
        <img id="baloon_4" class="baloon-infinite" :src="require('@/assets/images/calendar/baloon_1.png')" alt="baloon" />

        <img id="baloon_11" class="baloon-explosion" :src="require('@/assets/images/calendar/baloon_1.png')" alt="baloon" />
        <img id="baloon_12" class="baloon-explosion" :src="require('@/assets/images/calendar/baloon_2.png')" alt="baloon" />
        <img id="baloon_13" class="baloon-explosion" :src="require('@/assets/images/calendar/baloon_3.png')" alt="baloon" />
        <img id="baloon_14" class="baloon-explosion" :src="require('@/assets/images/calendar/baloon_4.png')" alt="baloon" />
        <img id="baloon_15" class="baloon-explosion" :src="require('@/assets/images/calendar/baloon_2.png')" alt="baloon" />
        <img id="baloon_16" class="baloon-explosion" :src="require('@/assets/images/calendar/baloon_3.png')" alt="baloon" />
        <img id="baloon_17" class="baloon-explosion" :src="require('@/assets/images/calendar/baloon_1.png')" alt="baloon" />
        <img id="baloon_18" class="baloon-explosion" :src="require('@/assets/images/calendar/baloon_2.png')" alt="baloon" />
        <img id="baloon_19" class="baloon-explosion" :src="require('@/assets/images/calendar/baloon_4.png')" alt="baloon" />
        <img id="baloon_20" class="baloon-explosion" :src="require('@/assets/images/calendar/baloon_1.png')" alt="baloon" />
    </Layout>
</template>

<script>
    import AvatarDefault from '@/assets/images/users/avatar-default-rect.png';
    export default {
        name: "Birthdays-Calendar",
        data() {
            return {
                AvatarDefault,
            }
        },
        mounted() {
            this.$store.dispatch('team/getBirthdaysCalendar');

            if(window.innerWidth >= 576){
                let tallestHeight = 0;
                document.querySelectorAll('.card').forEach((item) => {
                    const height = item.clientHeight;
                    if (height > tallestHeight) {
                        tallestHeight = height;
                    }
                });
                document.querySelectorAll('.card').forEach((item) => {
                    item.setAttribute(
                        'style',
                        'min-height:' + tallestHeight.toString() + 'px;'
                    );
                });
            }

            document.querySelectorAll('.day').forEach((item) => {
                if(item.innerHTML.toString().length <= 1)
                {
                    item.innerHTML = "0"+item.innerHTML;
                }
            });

        },
        methods: {
            setBirthdayAvatarDefault(event) {
                event.target.src = AvatarDefault
            }
        },
        computed: {
            birthdays() {
                return this.$store.state.team.birthdays;
            }
        }
    }
</script>

<style lang="scss" scoped>
  :deep(.page-content) {
    overflow: hidden;
    position: relative;
  }
    .birthdays-calendar{
        background-size: cover;
        padding: 30px;
        .card{
            background-color: unset;
            box-shadow: unset;
            min-height: 200px;
            .card-body{
                background-color: #e4e7f9;
                margin-top: 40px;
                padding: 10px 15px;
                border-radius: 0.25rem;
                h5{
                    margin: -50px 0 0 0;
                    color: #333333;
                }
                .birthdays-days-block{
                    margin-top: 30px;
                }
                .b-avatar-sm{
                    object-fit: cover;
                }
            }
        }
        .birthdays-days-row{
            .day{
                background-color: #FFFFFF;
                width: 32px;
                height: 32px;
                margin-right: 10px;
                color: #546ee5;
            }
        }
        .b-avatar-sm{
            width: 2rem;
            height: 2rem;
        }
    }
    @media (min-width: 1280px) {
        .birthdays-calendar{
            .card{
                max-width: 25%;
            }
        }
    }
    @media (max-width: 576px) {
        .birthdays-calendar {
            .card {
                min-height: unset;
            }
        }
    }

    .baloon-infinite{
        position: absolute;
        top: 100%;
        animation-duration: 40s;
        animation-iteration-count: infinite;
    }
    #baloon_1{
        animation-name: fly_1;
    }
    #baloon_2{
        animation-name: fly_2;
        animation-delay: 5s;
    }
    #baloon_3{
        animation-name: fly_3;
        animation-delay: 14s;
    }
    #baloon_4{
        animation-name: fly_4;
        animation-delay: 21s;
    }

    .baloon-explosion{
        position: absolute;
        top: 150%;
    }

    #baloon_11{
        width: 100px;
        left: 60%;
        animation-name: fly_15;
        animation-duration: 7s;
    }
    #baloon_12{
        left: 30%;
        animation-name: fly_12;
        animation-duration: 8s;
    }
    #baloon_13{
        left: 20%;
        animation-name: fly_13;
        animation-duration: 9s;
    }
    #baloon_14{
        width: 100px;
        left: 40%;
        animation-name: fly_15;
        animation-duration: 11s;
    }
    #baloon_15{
        left: 20%;
        animation-name: fly_15;
        animation-duration: 9s;
    }
    #baloon_16{
        left: 30%;
        animation-name: fly_15;
        animation-duration: 7s;
    }
    #baloon_17{
        left: 10%;
        animation-name: fly_11;
        animation-duration: 11s;
    }
    #baloon_18{
        width: 100px;
        left: 60%;
        animation-name: fly_13;
        animation-duration: 12s;
    }
    #baloon_19{
        left: 50%;
        animation-name: fly_12;
        animation-duration: 9s;
    }
    #baloon_20{
        left: 40%;
        animation-name: fly_11;
        animation-duration: 8s;
    }

    @keyframes fly_0 {
        0% {
            top: 100%;
            left: 80%;
            transform: rotate(-10deg);
        }
        50% {
            transform: rotate(15deg);
        }
        75% {
            transform: rotate(-15deg);
        }
        100% {
            top: -50%;
            left: 30%;
            transform: rotate(25deg);
        }
    }

    @keyframes fly_1 {
        0% {
            top: 100%;
            left: 0%;
            transform: rotate(15deg);
        }
        75% {
            transform: rotate(-15deg);
        }
        100% {
            top: -50%;
            left: 50%;
            transform: rotate(25deg);
        }
    }

    @keyframes fly_2 {
        20% {
            top: 100%;
            left: 80%;
            transform: rotate(15deg);
        }
        75% {
            transform: rotate(-15deg);
        }
        100% {
            top: -50%;
            left: 10%;
            transform: rotate(25deg);
        }
    }

    @keyframes fly_3 {
        30% {
            top: 100%;
            left: 20%;
            transform: rotate(15deg);
        }
        75% {
            transform: rotate(-15deg);
        }
        100% {
            top: -50%;
            left: 90%;
            transform: rotate(25deg);
        }
    }

    @keyframes fly_4 {
        30% {
            top: 100%;
            left: 60%;
            transform: rotate(15deg);
        }
        75% {
            transform: rotate(-15deg);
        }
        100% {
            top: -50%;
            left: 10%;
            transform: rotate(25deg);
        }
    }

    @keyframes fly_11 {
        0% {
            top: 100%;
            transform: rotate(-10deg);
        }
        25% {
            transform: rotate(15deg);
        }
        50% {
            transform: rotate(-15deg);
        }
        100% {
            top: -50%;
            right: 90%;
            transform: rotate(25deg);
        }
    }
    @keyframes fly_12 {
        0% {
            top: 100%;
            transform: rotate(20deg);
        }
        25% {
            transform: rotate(5deg);
        }
        50% {
            transform: rotate(-15deg);
        }
        100% {
            top: -50%;
            left: 90%;
            transform: rotate(5deg);
        }
    }

    @keyframes fly_13 {
        0% {
            top: 100%;
            transform: rotate(20deg);
        }
        25% {
            transform: rotate(-35deg);
        }
        50% {
            transform: rotate(25deg);
        }
        100% {
            top: -50%;
            left: 90%;
            transform: rotate(-25deg);
        }
    }
    @keyframes fly_14 {
        0% {
            top: 100%;
            transform: rotate(20deg);
        }
        25% {
            transform: rotate(5deg);
        }
        50% {
            transform: rotate(25deg);
        }
        100% {
            top: -50%;
            right: 90%;
            transform: rotate(-25deg);
        }
    }
    @keyframes fly_15 {
        0% {
            top: 100%;
            transform: rotate(15deg);
        }
        25% {
            transform: rotate(-25deg);
        }
        50% {
            transform: rotate(25deg);
        }
        100% {
            top: -50%;
            right: 90%;
            transform: rotate(-25deg);
        }
    }
</style>